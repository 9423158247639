export const mineLogic = () => {
	// 每个格子对应一个对象，对象内存储格子的各种信息，所有对象（格子）的的键值名对应它们的索引，这些对象存储在一个叫gridarr的对象内，可以通过索引访问到这些数据（gridarr[index]）,void对象是默认空数组，他的属性与空格子对象完全相同，存储了格子的最初始信息，在后面程序中可用于程序访问空格中属性使用。
	// 扫雷有三种难度，难度由横向格子数、纵向格子数、雷数这三个信息组成
	// 初级 8*8格，10个雷
	// 中级 16*16格，40个雷
	// 高级 32*16格，99个雷

	// 获取dom元素
	// 整体盒子
	const mian: HTMLElement = document.querySelector('.mian');
	// 存放游戏格子的盒子
	const box: HTMLElement = document.querySelector('.mian .box');
	// 显示标记数的盒子
	const minebox: HTMLElement = document.querySelector('.mian .mine_box');
	// 笑脸   提示箭头及重置按键
	const again: HTMLElement = document.querySelector(
		'.mian .againbox  .again'
	);
	const againbox: HTMLElement = document.querySelector('.mian .againbox');

	const leftbox: HTMLElement = document.querySelector(
		'.mian .againbox .left'
	);
	const rightbox: HTMLElement = document.querySelector(
		'.mian .againbox .right'
	);
	const timebox: HTMLElement = document.querySelector('.mian .time_box');
	// 显示台词
	const optiontext: HTMLElement = document.querySelector(
		'.mian .option .optiontext'
	);

	// 用于切换难度
	const optionbox: HTMLElement = document.querySelector(
		'.mian .option .optionbox'
	);
	const opt1: HTMLElement = document.querySelector('.mian .optionbox .opt1');
	const opt2: HTMLElement = document.querySelector('.mian .optionbox .opt2');
	const opt3: HTMLElement = document.querySelector('.mian .optionbox .opt3');
	const opt0: HTMLElement = document.querySelector('.mian .optionbox .opt0');
	const opt4: HTMLElement = document.querySelector('.mian .optionbox .opt4');

	// 全局变量：
	let DD = 'mid'; //存储难度的键值名
	let win = 1; // 胜利状态  失败0 进行 1 胜利2
	let flagnum = 0; //标记数
	let seconds = 0; //存储时间
	let time; //设置定时器
	let seconds2 = 0; //操作时间
	let time2 = 0; //操作时间定时器
	let lastgrid = 99999; //剩余未开格子数，渲染游戏数据时会存储为当前难度格子数
	let mine = 99999; //地雷数，渲染游戏数据时会存储为当前难度的雷数
	let li; //储存后续获取格子的DOM元素组，在渲染格子后获取元素
	let arr = []; //雷的位置索引，无雷为0，有雷为1
	let fncs; //全开或外挂函数  控制台输入 fncs() 回车，测试时可开启为自执行

	// 查找周边格子运算方法数组
	// 例：我要查找名为g（下图）、坐标为（x，y）格子周边格子的信息，如何获取这周边八格的坐标呢？
	// 1  2  3
	// 4  g  5
	// 6  7  8
	// 我要获取左上角格的坐标(对应的方法数组的第一个方法)，那么它的坐标就为（x + arrway[0] , y + arrway[0]）;将g格坐标遍历该数组方法，就可以获取周边8格的坐标
	const arrway = [
		[-1, -1],
		[0, -1],
		[1, -1],
		[-1, 0],
		[1, 0],
		[-1, 1],
		[0, 1],
		[1, 1],
	];
	//格子属性，默认为初始空格格子属性 存储数据 数据体
	let gridarr = {
		//存储格子信息
		void: {
			//默认/出界格属性
			x: 0, //格子所在的横坐标，非void格的x为正整数
			y: 0, //格子所在的纵坐标，非void格的y为正整数
			type: 'k', // 格子类型  雷:l/空格:k/数字:n
			grid_mine: 0, // 该格周边雷数  0-8的正整数  雷:100;空格:0;
			flag: 0, // 格子点开状态  0：默认、未点开；1:点开；2:标记
		},
	};
	// 定义难度等级
	let kk = {
		weak: {
			w_num: 16,
			h_num: 16,
			mine_num: 16,
		},
		low: {
			//初级
			w_num: 8, //横向格子数
			h_num: 8, //竖向格子数
			mine_num: 10, //地雷数
		},
		mid: {
			//中级
			w_num: 16,
			h_num: 16,
			mine_num: 40,
		},
		high: {
			//高级
			w_num: 32,
			h_num: 16,
			mine_num: 99,
		},
		custom: {
			//自定义  待开发
			w_num: 32,
			h_num: 24,
			mine_num: 160,
		},
	};
	// 数字图标
	const icon = {
		0: 'shuzi01',
		1: 'shuzi1',
		2: 'shuzi2',
		3: 'shuzi3',
		4: 'shuzi41',
		5: 'shuzi5',
		6: 'shuzi6',
		7: 'shuzi7',
		8: 'shuzi8',
		9: 'shuzi9',
		bj: 'biaoji',
		zd: 'zhadan', //炸弹
		tl: 'xiaolian-', //托脸
		xl: 'xiaolian', //笑脸
		kl: 'kulian', //哭脸
	};
	// 台词

	const aside = [
		'你失去了很多进步。那是一种深深的挫折感',
		'继续努力，别让它影响到你。',
		'我们所说的“失败”不是跌倒，而是停留在原地。',
		'走在路上感觉很糟糕',
		'我现在感受到的痛苦，是我以前拥有的幸福。这是交易',
		'不要站在我的墓前哭泣。我不在那里，我没有死。',
		'我可怜的玩家',
		'不要讨厌玩家;讨厌这个游戏',
		'你的失败在这里只是一个比喻。要学什么，请继续攀登',
		'你最好微笑着忘记。那你就应该记住后伤心',
		'沿着这条路走下去感觉很糟糕',
		'最后，我们只会后悔没有抓住机会',
		'人生没有遗憾，只有教训',
		'可怜的男孩离家很远',
		'每当我爬山时，我身后就跟着一只叫“自我”的狗',
	];
	console.log(aside);

	// 重置函数
	let reset = function () {
		// 重置全局变量
		win = 1; // 失败0 进行 1 胜利2
		flagnum = 0; //标记数
		// 重新渲染
		optiontext.innerHTML = '';
		fn0(DD);
		// 重置计时器函数
		fn3(0);
		// 重置时间
		seconds = 0;
		// 重置计时器盒子
		fn4(0);
		// 重置未点开格子数盒子
		fn5();
		// 重置为笑脸
		fn6(1);
		// 重置未操作时间
		fn7(null);
		// 重置难度选择
		optiontext.style.display = 'none';
		optionbox.style.display = 'flex';
		// 隐藏箭头
		leftbox.style.display = 'none';
		rightbox.style.display = 'none';
		// 重置按钮颜色
		opt0.classList.remove('yanse');
		opt1.classList.remove('yanse');
		opt2.classList.remove('yanse');
		opt3.classList.remove('yanse');
		opt4.classList.remove('yanse');
		switch (DD) {
			case 'weak':
				opt0.classList.add('yanse');
				fncs();
				let lstime = setTimeout(function () {
					alert(
						'小朋友，看来你遇到了一些困难，但这并不能够难倒你。来看看数字格（周边八格）周围的雷数与数字的对应关系，你就会懂得扫雷的玩法'
					);
				}, 100);
				break;
			case 'low':
				opt1.classList.add('yanse');
				break;
			case 'mid':
				opt2.classList.add('yanse');
				break;
			case 'high':
				opt3.classList.add('yanse');
				break;
			case 'custom':
				opt4.classList.add('yanse');
		}
	};
	// 重置按钮
	againbox.onclick = function () {
		reset();
	};

	// 难度选择函数
	function fn1() {
		opt0.onclick = function () {
			DD = 'weak';
			reset();
		};
		opt1.onclick = function () {
			DD = 'low'; // 设置难度信息
			reset(); // 重新渲染
		};
		opt2.onclick = function () {
			DD = 'mid';
			reset();
		};
		opt3.onclick = function () {
			DD = 'high';
			reset();
		};
		opt4.onclick = function () {
			DD = 'custom';

			// fn14();
			reset();
		};
	}
	fn1();

	// 渲染游戏（格子）
	// 渲染界面
	const fn0 = function (obj) {
		// 雷的位置索引，无雷为0，有雷为1
		arr = [];
		// 该难度下的横纵格数
		let w_num = kk[obj].w_num;
		let h_num = kk[obj].h_num;
		// 该难度下的总格数
		let gridnum = kk[obj].w_num * kk[obj].h_num;
		// 重置为点开格子数
		lastgrid = gridnum;
		// 该难度下的雷数
		mine = kk[obj].mine_num;
		// 函数内的临时数据
		let str = '';
		// 根据难度，渲染页面大小
		// 20游戏格子尺寸，10整体边框尺寸
		// 整体宽度 游戏区盒子宽度 游戏区盒子高度
		mian.style.width = 32 * w_num + 8 + 'px';
		box.style.width = 32 * w_num + 'px';
		box.style.height = 32 * h_num + 'px';

		// 生成储存游戏格子数据结构的对象   以后称为数据体
		let str2 = '';
		for (var i = 0; i < gridnum; i++) {
			gridarr[i] = {};
			gridarr[i] = {};
			gridarr[i].x = fn9('x', i);
			gridarr[i].y = fn9('y', i);
			gridarr[i]['type'] = 'k';
			gridarr[i]['grid_mine'] = 0;
			gridarr[i]['flag'] = 0;
			// 生成空数组，后续用于存储雷的位置
			arr.push(0);
			// 拼接小游戏格
			str2 += `<li class=${i}>
                     </li>`;
		}

		// 渲染游戏格
		box.innerHTML = str2;
		// 生成有雷的数组，雷的位置，标记为1
		for (let i = 0; i < mine; i++) {
			let num = Math.floor(gridnum * Math.random());
			if (arr[num] === 0) {
				arr[num] = 1;
			} else {
				i--;
			}
		}

		// 构建构建雷位置数据和数字格子数据
		function build(rrrset) {
			// 修改数据体,添加雷的参数到数据体
			arr.forEach((value, index) => {
				if (value) {
					gridarr[index]['type'] = 'l';
					gridarr[index]['grid_mine'] = 100;
				} else {
					// console.log(gridarr[index]);
					gridarr[index]['type'] = 'k';
					gridarr[index]['grid_mine'] = 0;
				}
			});
			// 遍历添加雷后的数据体，判定空格中哪些有数字，并修改有数字格子的数据体
			for (var i = 0; i < gridnum; i++) {
				// 如果该序号格子类型为'k',空,就执行查雷函数
				if (gridarr[i]['type'] === 'k') {
					let num = 0;
					num = fn10(i, null);
					if (num) {
						// 改变该格数据的类型和雷数
						gridarr[i].type = 'n';
						gridarr[i].grid_mine = num;
					}
				}
			}
		}
		build(null);
		// 点击事件
		li = document.querySelectorAll('.mian .box li');
		li.forEach((item, index) => {
			// 鼠标点击事件 左键
			item.onclick = function () {
				//清除未操作时间计时器
				fn7(null);
				// 第一次点击时开启定时器 未点开格子数等于雷数
				if (lastgrid === gridnum) {
					fn3(1);
				}
				// 如果第一次点击的是雷，重新更新
				// 格子数等于未打开格子数，且该格为雷
				if (DD !== 'weak') {
					if (lastgrid === gridnum && gridarr[index].type === 'l') {
						for (let i = 0; i < 1; i++) {
							// 生成0-格子数-1的随机数
							let k = Math.floor(gridnum * Math.random());
							// 检测随机数作为索引位置是否是雷
							if (arr[k] === 0) {
								// 是空，交换本格数据与随机格数据
								arr[index] = 0;
								arr[k] = 1;
								break;
							} else {
								// 是雷，从新生成随机数
								i--;
							}
						}
						// 跟据新生成的雷位置数组，重新构建数据体
						build(null);
						// 重新构建数据体，打开本格
						fn8(item, index);
						// 阻止本次向下执行
						return false;
					}
				}

				// 如果状态为1（进行），执行点击事件，如果为0或2，则不执行
				if (win === 1) {
					// 如果这个格子没有标记
					if (gridarr[index].flag === 0) {
						fn8(item, index);
					}
					// 胜利检测函数
					// 当剩余未点击数等于地雷数时胜利
					if (mine === lastgrid) {
						// 清除计时器函数
						fn3(0);
						// 设置胜利状态
						win = 2;
						// 清除未操作计时器
						fn7(null);

						optiontext.style.display = 'block';
						optionbox.style.display = 'none';
						// 显示箭头
						leftbox.style.display = 'block';
						rightbox.style.display = 'block';
						if (DD === 'weak') {
							alert(
								'小朋友看样子你已经学会了扫雷的玩法，快去征服宇宙吧！'
							);
						}
						fn12();
						optiontext.innerHTML = '这次算你赢';
					}
				}
				// 开启未操作时间计时器
				if (win === 1) {
					fn7('set');
				}
			};
			// 鼠标右击事件 标记
			item.oncontextmenu = function () {
				//清除未操作时间计时器
				fn7(null);

				if (win === 1) {
					fn6(1);
					if (gridarr[index].flag === 0) {
						gridarr[index].flag = 2;
						item.innerHTML = `     
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-biaoji"></use>
                            '</svg>`;
						flagnum++;
					} else if (gridarr[index].flag === 2) {
						gridarr[index].flag = 0;
						item.innerHTML = '';
						flagnum--;
					}
					fn5();
				}
				// 开启未操作时间计时器
				fn7('set');
				// 阻止默认事件（菜单）
				return false;
			};
		});

		// 调试用全开函数
		fncs = function () {
			for (let i = 0; i < gridnum; i++) {
				let x = fn9('x', i);
				let y = fn9('y', i);
				li[i].style.background = '#8aa';
				switch (gridarr[i]['type']) {
					case 'l': // 点开的是雷
						// 添加雷图标
						li[i].innerHTML = `     
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-zhadan"></use>
                            '</svg>`;
						break;
					case 'n': //点开的是数字
						// 获取数字（地雷数）并渲染
						let num = gridarr[i].grid_mine;
						li[i].innerHTML = `
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-${icon[num]}"></use>
                        </svg>`;
						break;
					case 'k':
				}
			}
			return '多low哦！';
		};
	};
	fn0(DD);

	// 计时器
	const fn3 = function (set: number) {
		if (set) {
			time = window.setInterval(function () {
				// 233 	fn4();
				fn4(1);
				seconds++;
			}, 1000);
		}
		if (set === 0) {
			clearInterval(time);
		}
	};
	// 计时器盒子渲染
	const fn4 = function (kk) {
		let a = (seconds + 1) % 10;
		let b = ((seconds + 1 - a) % 100) / 10;
		let c = (seconds + 1 - a - b * 10) / 100;
		if (seconds > 998) {
			a = 9;
			b = 9;
			c = 9;
		}
		if (kk === 0) {
			a = 0;
			b = 0;
			c = 0;
		}
		timebox.innerHTML = `
                            <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-${icon[c]}"></use>
                            '</svg>
                            <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-${icon[b]}"></use>
                            '</svg>
                            <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-${icon[a]}"></use>
                            '</svg>`;
	};
	fn4(0);

	// 雷数显示器
	const fn5 = function () {
		let kk = mine - flagnum;
		if (kk < 0) {
			kk = 0;
		}
		let a = kk % 10;
		let b = ((kk - a) % 100) / 10;
		let c = (kk - a - b * 10) / 100;
		if (kk > 999) {
			a = 9;
			b = 9;
			c = 9;
		}
		minebox.innerHTML = `
                            <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-${icon[c]}"></use>
                            '</svg>
                            <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-${icon[b]}"></use>
                            '</svg>
                            <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-${icon[a]}"></use>
                            '</svg>`;
	};
	fn5();

	// 笑脸图标切换
	function fn6(a) {
		const fnn = function (k) {
			again.innerHTML = `
                            <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-${icon[k]}"></use>
                            '</svg>`;
		};
		if (a) {
			fnn('tl');
			let asd = window.setInterval(function () {
				fnn('xl');
				clearInterval(asd);
			}, 260);
		} else {
			fnn('kl');
		}
	}
	// 未操作时间计时器
	function fn7(set: any) {
		console.log(12344444);
		// 胜利时结束
		if (win === 2) {
			clearInterval(time2);
			return false;
		}
		if (set) {
			time2 = window.setInterval(function () {
				let tt = seconds2 % 15;
				// 是15秒的倍数，且不为零
				if (!tt && seconds2 !== 0) {
					let l = Math.floor(aside.length * Math.random());
					optiontext.innerHTML = `
                    ${aside[l]}`;
					optiontext.style.display = 'block';
					optionbox.style.display = 'none';
				}
				seconds2++;
			}, 1000);
		}
		if (!set || set === 'clear') {
			clearInterval(time2);
		}
	}

	// 翻开格子函数  点击时切换图标等  函数
	// 传入 2个参数，（item,index）
	function fn8(item, index) {
		// 如果这个格子已经标记或打开，不执行
		if (gridarr[index]['flag'] !== 0) {
			return false;
		}
		let str = '';
		// 格子状态切换为点开
		gridarr[index].flag = 1;
		// 改变背景颜色
		item.style.background = '#f2f2f2';
		// 点开
		switch (gridarr[index]['type']) {
			case 'l': // 点开的是雷
				// 添加雷图标
				item.innerHTML = `     
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-zhadan"></use>
                            '</svg>`;
				// 如果游戏还没结束
				if (win === 1) {
					// 设置胜利状态 游戏结束
					// 清除定时器
					fn3(0);
					// 切换为哭脸
					fn6(0);
					// 清除标记计数器
					fn7(null);
					// 胜利状态设为0
					win = 0;
					// 渲染
					optiontext.style.display = 'block';
					optionbox.style.display = 'none';

					leftbox.style.display = 'block';
					rightbox.style.display = 'block';
					if (DD === 'weak') {
						optiontext.innerHTML = '小朋友不要气馁，再试试';
					} else {
						optiontext.innerHTML = '再回去上幼儿园怎么样';
					}
				}
				break;
			case 'n': //点开的是数字
				// 未点开格子数减一
				lastgrid--;
				// 笑脸动作
				fn6(1);
				// 获取数字（地雷数）并渲染
				let num = gridarr[index].grid_mine;
				item.innerHTML = `
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-${icon[num]}"></use>
                        </svg>`;
				break;
			case 'k':
				// 笑脸动作
				fn6(1);
				// 未点开格子数减一
				lastgrid--;
				// 打开周边的格子
				fn11(index);
		}
	}

	// fn9() 位置坐标、序号互查
	//  通过坐标查询序号时，传入参数为（行数，列数）
	//  通过序号查询x坐标时，传入参数为（'x',序号）
	//  通过序号查询y坐标时，传入参数为（'y',序号）
	function fn9(n, m) {
		let data;
		//获取难度设置下的宽高格数
		let w = kk[DD].w_num;
		let h = kk[DD].h_num;
		// 传入的n是数字，查找序号
		if (!isNaN(n)) {
			// 当传入的是出界格时，定位到数据体void属性（默认属性）
			if (n <= 0 || n > w || m <= 0 || m > h) {
				return 'void';
			}
			return (m - 1) * w + n - 1; //返回序号
		}
		// 传入的n是'x',查找x坐标
		if (n === 'x') {
			let juk = m + 1;

			if (juk % w === 0) {
				return w;
			} else {
				return juk % w;
			}
		}
		// 传入的n是'y',查找y坐标
		if (n === 'y') {
			return (m + 1 - fn9('x', m)) / w + 1;
		}
	}

	// fn10()查找周边地雷数
	// 说明：传入序号，返回雷数
	function fn10(i, kkk) {
		// 拿到检测格的横纵坐标
		let xx = gridarr[i].x;
		let yy = gridarr[i].y;
		if (kkk) {
			console.log('x:' + xx + 'y:' + yy);
		}
		// 设置雷数初始格为零。
		let num = 0;
		for (let k = 0; k < 8; k++) {
			let xxx = xx + arrway[k][0];
			let yyy = yy + arrway[k][1];
			let hh = fn9(xxx, yyy);
			if (gridarr[hh]['type'] === 'l') {
				num++;
			}
			if (kkk) {
				console.log(arrway[k][0] + '..' + arrway[k][1]);
				console.log('x:' + xxx + 'y:' + yyy + '序数：' + hh);
				console.log(num);
			}
		}
		return num;
	}

	// 输入当前格的索引，打开周边格
	function fn11(index) {
		// 输入当前格的索引，打开周边格
		// 拿到检测格的横纵坐标
		let xx = gridarr[index].x;
		let yy = gridarr[index].y;
		for (let i = 0; i < 8; i++) {
			// 获取格子序号
			let z = fn9(xx + arrway[i][0], yy + arrway[i][1]);
			// 获取格子是否打开
			let flag = gridarr[z]['flag'];
			// 获取格子类型
			let type = gridarr[z]['type'];
			// 获取对应序列的元素
			let item = li[z];
			// 如果格子没打开，且格子不是雷，且序号不是void
			if (flag === 0 && type !== 'l' && z >= 0) {
				// 点开当前的格子
				fn8(item, z);
				if (type === 'k') {
					//如果当前格子是空，回调自身，查找该格的周边空格
					fn11(z);
				}
			}
		}
	}

	// 胜利彗星流
	function fn12() {
		// 出现时间
		// 出现波次
		// 星体图标及大小
		// 思路,在body内添加一个绝对定位盒子,在盒子内生产流星位置，添加流星位置，通过移动盒子实现效果
		// 其他要求：
		const body = document.body;
		let seewidth = document.documentElement.clientWidth;
		let seeheight = document.documentElement.clientHeight;
		let starheight = 60;
		let starwidth = 60;
		let starimg = document.createElement('img');
		starimg.setAttribute('src', './star.png');
		starimg.style.cssText = `
                display:block;
                width:40px;
                height:40px;
                position:absolute;
                top:${-starheight}px;
                left:${-starwidth}px;
        `;
		body.appendChild(starimg);
		let bnum = 0;
		let bt = 0.5; //流星雨持续时长
		let aa = setInterval(function () {
			bnum++;
			let topk = -starheight;
			let k = starimg.cloneNode(true) as HTMLImageElement;
			body.appendChild(k);
			k.style.left = fn13(seewidth) + 'px';
			let t = setInterval(function () {
				k.style.top = topk + 'px';
				topk += 6;
				if (topk > seeheight + starheight) {
					body.removeChild(k);
					clearInterval(t);
					if (bnum > bt * 10) {
						clearInterval(aa);
					}
				}
			}, 1000 / 60);
		}, 50);
	}

	// 随机数函数
	function fn13(num) {
		// 输入一个数，返回它到0之间的随机整数，向下取整
		let a = Math.floor(Math.random() * num);
		return a;
	}

	// function fn14() {
	//     let hang = window.prompt('输入行数');
	//     let lie = window.prompt('输入列数');
	//     let lei = window.prompt('输入雷数');

	//     function nn(danum, title) {
	//         let w = prompt('输入' + title + '数');
	//         if (w <= danum) {
	//             alert(title + '不能小于' + 'danum');
	//             nn();
	//         } else {
	//             return w;
	//         }
	//     }
	//     kk.custom.w_num = nn(0, '行');
	//     kk.custom.h_num = nn(8, '列');
	//     kk.custom.mine_num = nn(1, '雷');

	// }
	// 开发人员 张广乐
};
