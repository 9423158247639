import { Minesweeper } from './page/demo/minesweeper/index';

function App() {
	return (
		<div className="App">
			{/* <header className="App-header">
				<p>
					23333 <code>src/App.js</code> and save to reload.
				</p>
				<a
					className="App-link"
					href="https://reactjs.org"
					target="_blank"
					rel="noopener noreferrer"
				>
					Learn React
				</a>
			</header> */}
			<Minesweeper></Minesweeper>
		</div>
	);
}

export default App;
