import './index.css';
import { mineLogic } from './game';
import React, { useEffect } from 'react';

export const Minesweeper = () => {
	useEffect(() => {
		mineLogic();
	}, []);
	return (
		<div className="mian">
			<h5>扫雷</h5>
			<div className="option">
				<div className="optionbox">
					<div className="opt0">幼稚园</div>
					<div className="opt1">小学生</div>
					<div className="opt2 yanse">初中生</div>
					<div className="opt3">高中生</div>
					<div className="opt4">上大学</div>
				</div>
				<div className="optiontext"></div>
			</div>
			<div className="head">
				<div className="mine_box"></div>
				<div className="againbox">
					<div className="left">---&gt;</div>
					<div className="right">&lt;---</div>
					<div className="again">
						<svg className="icon xiaolian" aria-hidden="true">
							<use xlinkHref="#icon-xiaolian"></use>'
						</svg>
					</div>
				</div>
				<div className="time_box"></div>
			</div>
			<ul className="box"></ul>
		</div>
	);
};
